




















import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from '@vue/composition-api'

import HLine from '@/components/atoms/HLine.vue'
import DateGroup from '@/components/organisms/h/item/date/DateGroup.vue'
import ItemHierarchyGroup from '@/components/organisms/h/item/hierarchy/ItemHierarchyGroup.vue'
import ExtractConditionLayout from '@/components/organisms/h/layout/ExtractConditionLayout.vue'
import {
  ItemOrderNumAdjExtractCondition,
  dateGroupExtractCondition,
  itemHierarchyGroupExtractCondition,
  itemHierarchyUseList,
  dateRange,
} from '@/types/entities'

export default defineComponent({
  components: {
    ExtractConditionLayout,
    DateGroup,
    ItemHierarchyGroup,
    HLine,
  },
  props: {
    defaultDateValue: {
      type: Array as PropType<string[]>,
      default: () => ['', ''],
    },
  },
  setup(props, context) {
    /*********************************
     * 表示する選択肢
     *********************************/
    // Todo: TanaUseListにクラス、カテゴリ追加
    const itemStoreUseList = ref<itemHierarchyUseList>([
      ['Chubunrui', 'Shobunrui', 'Class', 'Category', 'Tanpin'],
    ])
    /*********************************
     * データ
     *********************************/
    const extractConditionOfDate = ref<dateGroupExtractCondition>({})
    const extractConditionOfItemStore = ref<itemHierarchyGroupExtractCondition>(
      {}
    )

    /*********************************
     * emitデータの作成
     *********************************/
    // いずれかの抽出条件変更時の処理
    const changeExtractCondition = () => {
      const base: ItemOrderNumAdjExtractCondition = {
        validData:
          extractConditionOfDate.value.dateRange ||
          (props.defaultDateValue as dateRange),

        chubunrui: extractConditionOfItemStore.value.chubunrui || [],
        chubunruiCount: extractConditionOfItemStore.value.chubunruiCount || 0,
        shobunrui: extractConditionOfItemStore.value.shobunrui || [],
        shobunruiCount: extractConditionOfItemStore.value.shobunruiCount || 0,
        class: extractConditionOfItemStore.value.class || [],
        classCount: extractConditionOfItemStore.value.classCount || 0,
        category: extractConditionOfItemStore.value.category || [],
        categoryCount: extractConditionOfItemStore.value.categoryCount || 0,
        tanpin: extractConditionOfItemStore.value.tanpin || [],
        tanpinCount: extractConditionOfItemStore.value.tanpinCount || 0,
      }

      let cond = { ...base }
      // console.log('changeExtractCondition', { cond })
      context.emit('change-extract-condition', cond)
    }

    /*********************************
     * 以下は各抽出条件変更時の処理
     *********************************/
    const changeExtractConditionOfDate = (
      params: dateGroupExtractCondition
    ) => {
      extractConditionOfDate.value = params
      changeExtractCondition()
    }
    const changeExtractConditionOfItemStore = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      extractConditionOfItemStore.value = params
      changeExtractCondition()
    }

    const requiredItemInput = [
      '有効日：常に入力',
      '商品のいずれかを一つ以上選択',
    ]
    return {
      requiredItemInput,
      itemStoreUseList,
      changeExtractConditionOfDate,
      changeExtractConditionOfItemStore,
    }
  },
})
